import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as actions from "../redux/actions/authActions/auth.actions";
// import  localization  from "../json/lang/conf.json";
import BoxCenter from "./authPage/UI/box/Center";
import Typography from "@material-ui/core/Typography";
import Button  from '@material-ui/core/Button';



const PageInfo = (props) => {
  const localization = useSelector((state) => state.authReducer.lang_values);
  const language = useSelector(state => state.authReducer.language);
  const isConfirm = useSelector((state) => state.authReducer.isConfirm);
  const [info, setInfo] =  useState('')
  const [hoverReg, setHoverReg] = useState(false);
  const dispatch = useDispatch();
  const activateUser = useCallback(
    (hist, hash) => dispatch(actions.activateUser(hist,hash)),
    [dispatch]
  );
  useEffect(()=>{
    console.log(window.location)
    let hash = window.location.hash;
    if(hash.length===1 || hash.length===0) {
       window.location.path = '/';
       return
    }
    if(hash.length < 16){
      if(hash==='not_confirmed'){
        setInfo('not_confirmed');
        return
      }
      if(hash==='404'){
        setInfo('404');
        return
      }
      if(hash==='access_denied'){
        setInfo('access_denied');
        return
      }
      setInfo('unkErr');
      return
    }
    activateUser(props.history, hash).then( (response) => {
        debugger
        if(response.data.code ===200 || response.data.code ===201){
          setInfo('successActivate');
        }


      
        if(response.data.code ===401){
          setInfo('401')
        }
      })
      .catch((error) => {
        console.log('ERROR', error);
        // addToast('error',  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })

      });
  }, [])

    return language && localization ?  (
      <BoxCenter logIntoReg={()=>props.history.push('/')}>

      <Typography component="h1" variant="h5" style={{textAlign: 'center',  marginTop: 30, marginBottom: 20, textTransform:'none', fontWeight: 'bold', fontSize: 20, color: '#2F2A61', fontFamily: 'gilroy'}}>
        {info==='successActivate' ? localization.jointSuccesses.success : localization.jointErrors.error }
      </Typography>

      <Typography component="h1" variant="body1" style={{textAlign: 'center',  marginTop: 10, marginBottom: 20, textTransform:'none', fontWeight: 'normal',  color: '#2F2A61', fontFamily: 'gilroy'}}>
        {info==='successActivate' ? localization.page_info.page_infoTexts.successActivate : localization.page_info.page_infoErrors[info]}
      </Typography>


      <Button
          style={{
            backgroundColor: "#5e5ab9",
            color: "#fff",
            marginTop: 30,
            height: 40,
            borderRadius: 20,
            textTransform: "none",
            fontWeight: "bold",
            fontSize: 14,
            fontFamily: "gilroy",
            width: "100%",
          }}
          onClick={()=>{window.location.href = '/';  }}
          variant="contained"
          disabled={!isConfirm ? true : false}

          margin="normal"
        >
          {localization.page_info.page_infoBtnsAndlinks.redirectOnMain}
        </Button>

        <Typography
        onClick={() => props.history.goBack()}
        onMouseLeave={() => setHoverReg(false)}
        onMouseEnter={() => setHoverReg(true)}
        style={
          !hoverReg
            ? {
                marginTop: 15,
                color: "#999bba",
                fontSize: 14,
                fontFamily: "gilroy",
                fontSize: 15,
              }
            : {
                marginTop: 15,
                fontSize: 14,
                fontFamily: "gilroy",
                color: "white",
                cursor: "pointer",
                fontSize: 15,
                color: "#5e5ab9",
              }
        }
      >
        {localization.page_info.page_infoBtnsAndlinks.goBack}
      </Typography>

      </BoxCenter>

  ) : null ;
};
  
export default PageInfo;