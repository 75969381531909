import React from 'react';
import BoxCenter from "./authPage/UI/box/Center";
import { useDispatch, useSelector } from "react-redux";

const NoNoNo = () => {
  const localization = useSelector((state) => state.authReducer.lang_values);
    return (
    <BoxCenter>
    {/* <div style={{  margin: '0 auto' }}> */}
    <h2 style={{color:'#2F2A61', textAlign: 'center'}}>{localization.achtung.achtungTexts.achtungTitle}
 </h2>
      <p style={{color:'#2F2A61',  textAlign: 'center'}}>{localization.achtung.achtungTexts.achtungText}
</p>
    {/* </div> */}
    </BoxCenter>  
  )};
export default NoNoNo;