import Cookies from 'js-cookie';
import  conf  from "../json/conf.json";
export const isAuthGuard = (to, from, next) => {
    if (to.meta.auth) {
      // debugger
      // let login =  Cookies.get('hash_key');
      // if(login){
      //   debugger
      //   next.redirect('/authorizated');
      // }
      next();
    } else {
      next();
    }
};



