import React, { useEffect, useCallback } from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useInput } from "../../../hooks/useInput";
import validator from "validator";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../redux/actions/authActions/auth.actions";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { useToasts } from "react-toast-notifications";
import DialogContent from "@material-ui/core/DialogContent";
import confLocalization from "../../../../../public/json/lang/conf.json";
import DialogTitle from "@material-ui/core/DialogTitle";
import { logo } from "./../../../../assets/images";
import sha1 from 'sha1';
import { Link } from "react-router-dom";
import AuthService from "./../../../../services/auth.service";
import Cookies from 'js-cookie';
import  moment  from 'moment';

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#5e5ab9",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#5e5ab9",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#5e5ab9",
      },
      "&:hover fieldset": {
        borderColor: "#5e5ab9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#5e5ab9",
      },
    },
  },
})(TextValidator);

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  // formControl: {
  //   marginTop: 80,
  //   minWidth: 120
  // },
  paper: {
    // marginTop: theme.spacing.unit * 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    [theme.breakpoints.down("xs")]: {
      alignItems: "initial",
    },

    background: "linear-gradient(0deg, #E3E9F7, #E3E9F7)",
    boxShadow:
      "-5px -5px 14px rgba(255, 255, 255, 0.570094), 10px 10px 20px rgba(0, 0, 0, 0.0826595)",
    borderRadius: 16,
  },

  avatar: {
    margin: theme.spacing.unit,
  },
  text: {
    textAlign: "center",
  },
  cent: {
    display: "flex",
    // alignItems: 'center',
    // marginTop: 200s
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "100%",
    marginBottom: 200,
  },

  dividerTop: {
    width: "100%",
    height: 15,
    position: "absolute",
    top: 0,
    backgroundColor: "#2E5BFF",
  },

  localBtns: {
    position: "absolute",
    top: 10,
    right: 10,
    display: "flex",
    flexDirection: "column",
    maxWidth: 65,
  },

  contUs: {
    position: "absolute",
    top: 16,
    left: 10,
    display: "flex",
    cursor: "pointer",
  },
  logo: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  typ: {
    fontStyle: "normal",
    marginTop: 30,
    fontWeight: "bold",
    marginLeft: "5%",
    color: "#0F34BB",
    padding: 0,
    fontSize: 18,

    //  width: 276,
    fontFamily: "gilroy",
    cursor: "pointer",
    textTransform: "uppercase",
  },

  formControl: {
    width: "100%",
  },
});

const BoxCenter = ({ classes, children, logIntoReg, setInternetConnDi }) => {
  const localization = useSelector((state) => state.authReducer.lang_values);
  const { addToast } = useToasts();

  useEffect(() => {
    ValidatorForm.addValidationRule("moreThanThreeChar", (value) => {
      return value.length >= 4 && value.length <= 40;
    });
    ValidatorForm.addValidationRule("notAllowedSpecialSymbols", (value) => {
      return !/[^A-zА-яЁё0-9]/.test(value);
    });
    ValidatorForm.addValidationRule("zipCodeValidation", (value) => {
      return validator.isNumeric(value);
    });
    ValidatorForm.addValidationRule("phoneEmailValidation", (value) => {
      return validator.isMobilePhone(value) || validator.isEmail(value);
    });

    return () => {
      ValidatorForm.removeValidationRule("moreThanThreeChar");
      ValidatorForm.removeValidationRule("moreThanThreeChar");
    };
  }, [ValidatorForm]);

  const language = useSelector((state) => state.authReducer.language);
  const langConf = useSelector((state) => state.authReducer.lang_conf);

  const [car, setCar] = React.useState(
    Object.keys(confLocalization).findIndex((item) => item === language) || 0
  );
  const dispatch = useDispatch();
  const isConfirm = useSelector((state) => state.authReducer.isConfirm);
  const {
    value: username,
    bind: bindUserName,
    reset: resetUserName,
    setValue,
  } = useInput("");
  const { value: contact, bind: bindContact, reset: resetContact } = useInput(
    ""
  );
  const { value: bodyMsg, bind: bindBodyMsg, reset: resetBodyMsg } = useInput(
    ""
  );
  const [showContactForm, setShowContactForm] = React.useState(false);
  const [theme, setTheme] = React.useState(0);
  const changeLanguageLeft = useCallback(() => {
    if (car === 0) {
      setCar(Object.keys(confLocalization).length - 1);
      dispatch(
        actions.setLanguage(
          Object.keys(confLocalization)[
            Object.keys(confLocalization).length - 1
          ]
        )
      );
      return;
    }
    setCar(car - 1);
    dispatch(actions.setLanguage(Object.keys(confLocalization)[car - 1]));
  }, [dispatch, car, confLocalization]);
  const changeLanguage = useCallback(
    (lang) => dispatch(actions.setLanguage(lang)),
    [dispatch]
  );

  const [age, setAge] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    console.log(event.currentTarget);

    setAge(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  // const changeLanguage = useCallback(
  //   () =>{

  //     if(car===Object.keys(confLocalization).length-1){
  //       setCar(0)
  //       dispatch(actions.setLanguage(Object.keys(confLocalization)[0]))
  //       return;
  //     }
  //     setCar(car+1)
  //     dispatch(actions.setLanguage(Object.keys(confLocalization)[car+1]))
  //   },
  //   [dispatch, car, confLocalization]
  // );

  const handleSubmitCallback = useCallback(async () => {
    debugger;
    await AuthService.contactForm({
      theme: localization.contact_us.contact_usForm.themes[theme],
      name: username,
      contact,
      text: bodyMsg,
      lang: language,
    });
    setShowContactForm(!showContactForm);
    addToast("We will contact you shortly!", {
      appearance: "info",
      autoDismissTimeout: 2000,
      autoDismiss: true,
    });
  }, [theme, username, contact, bodyMsg, language]);

  return (
    <>
      <div className={classes.cent}>
        <Link
          to={"/"}
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 60,
          }}
        >
          <img
            src={logo}
            alt="ertert"
            className={classes.logo}
            style={{
              marginRight: "auto",
              marginLeft: "auto",

              cursor: "pointer",
              width: "12%",
              minWidth: 150,
            }}
          />
        </Link>

        <div className={classes.localBtns}>
          <Button
            onClick={() => setOpen(!open)}
            style={{ backgroundColor: "#8986e3", color: "#f2f2f2" }}
          >
            {language.toUpperCase()}
          </Button>

          <Paper
            style={
              open ? { display: "block", marginTop: 10 } : { display: "none" }
            }
          >
            {Object.keys(langConf.language_arr).map((item, index) => (
              <Button
                onClick={() => {
                  setOpen(false);
                  changeLanguage(item);
                }}
                value={index}
                style={{}}
              >
                {item.toUpperCase()}
              </Button>
            ))}
          </Paper>
        </div>
        <div
          className={classes.contUs}
          onClick={navigator.onLine ? ()=>setShowContactForm(true) :() =>  {
            let login = Cookies.get("hash_key");
            let errforLog = {
              timestamp: moment().unix(),
              login_hash: login ? login : "",
              point_error: "contact us",
              error_msg: "Internet connection error",
            };
            let logos = JSON.parse(localStorage.getItem("logos"));
            logos.arr.push(errforLog);
            localStorage.setItem(
              "logos",
              JSON.stringify({ key: sha1(logos.arr), arr: logos.arr })
            );
            setInternetConnDi(true);
          }}
        >
          <MailOutlineOutlinedIcon
            fontSize="small"
            style={{ color: "#57559a", marginRight: 10 }}
          />
          <Typography
            style={{ color: "#57559a", marginRight: 10 }}
            onClick={() => {}}
          >
            {localization.contact_us.title.contUs}
          </Typography>
        </div>
        <main className={classes.main}>
          <div className={classes.paper}>{children}</div>
        </main>

        <Dialog
          maxWidth={"xs"}
          open={showContactForm}
          onClose={() => setShowContactForm(!showContactForm)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              color: "#5e5ab9",
              textAlign: "center",
              backgroundColor: "#e3e9f7",
              fontFamily: "gilroy",
            }}
          >
             {localization.contact_us.title.contUs}
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#e3e9f7" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <FormControl className={classes.formControl}>
                  {/* <InputLabel id="demo-simple-select-label">
                  Select theme
                </InputLabel> */}
                  <Select
                    // autoWidth={true}
                    style={{
                      height: 44,
                      background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
                      boxShadow:
                        "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
                      borderRadius: 12,
                      // minWidth: 157,
                      color: "#2F2A61",
                      // marginTop: 50,
                    }}
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={theme}
                    variant="filled"
                    placeholder="Select theme"
                    onChange={(event) => setTheme(event.target.value)}
                  >
                    {localization.contact_us.contact_usForm.themes.map((item, index) => (
                      <MenuItem value={index} style={{ minWidth: 157 }}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <ValidatorForm
                onSubmit={() => {
                  debugger;
                  handleSubmitCallback();
                }}
              >
                <CssTextField
                  fullWidth
                  variant="filled"
                  // variant="outlined"
                  style={{
                    height: 44,
                    background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
                    boxShadow:
                      "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
                    borderRadius: 12,
                    marginTop: 20,
                    color: "#2F2A61",
                  }}
                  InputProps={{
                    style: {
                      background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
                      boxShadow:
                        "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
                      borderRadius: 12,
                    },
                    underline: {
                      "&&&:before": {
                        borderBottom: "none",
                      },
                      "&&:after": {
                        borderBottom: "none",
                      },
                    },
                  }}
                  placeholder={localization.contact_us.contact_usForm.placeHolders.yourName}
                  {...bindUserName}
                  name="username"
                  validators={[
                    "required",
                    "moreThanThreeChar",
                    "notAllowedSpecialSymbols",
                  ]}
                  errorMessages={[
                    localization.jointFormData.errors.reqValid, 
                    localization.jointFormData.errors.lengthValid,
                    localization.jointFormData.errors.specialSymbolsValid,
                  ]}
                  margin="dense"
                />
                <CssTextField
                  fullWidth
                  style={{
                    height: 44,
                    background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
                    boxShadow:
                      "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
                    borderRadius: 12,
                    marginTop: 20,
                    color: "#2F2A61",
                  }}
                  InputProps={{
                    style: {
                      background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
                      boxShadow:
                        "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
                      borderRadius: 12,
                    },
                    underline: {
                      "&&&:before": {
                        borderBottom: "none",
                      },
                      "&&:after": {
                        borderBottom: "none",
                      },
                    },
                  }}
                  // variant="outlined"
                  placeholder={localization.contact_us.contact_usForm.placeHolders.yourContact}
                  variant="filled"
                  {...bindContact}
                  name="contact"
                  validators={["required", "moreThanThreeChar"]}
                  errorMessages={[
                    localization.jointFormData.errors.reqValid, 
                    localization.jointFormData.errors.lengthValid
                  ]}
                  margin="dense"
                />
                <CssTextField
                  fullWidth
                  placeholder={localization.contact_us.contact_usForm.placeHolders.yourMsgForUs}
                  style={{
                    background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
                    boxShadow:
                      "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
                    borderRadius: 12,
                    marginTop: 40,
                    color: "#2F2A61",
                  }}
                  InputProps={{
                    style: {
                      background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
                      boxShadow:
                        "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
                      borderRadius: 12,
                    },
                    underline: {
                      "&&&:before": {
                        borderBottom: "none",
                      },
                      "&&:after": {
                        borderBottom: "none",
                      },
                    },
                  }}
                  variant="filled"
                  {...bindBodyMsg}
                  rows={5}
                  multiline
                  name="bodyMsg"
                  validators={["required"]}
                  errorMessages={[localization.jointFormData.errors.reqValid, ]}
                  margin="dense"
                />
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#5e5ab9",
                      color: "#fff",
                      marginTop: 15,
                      height: 30,
                      width: "100%",
                    }}
                    variant="contained"
                    type="submit"
                    // onClick={() => handleSubmitCallback()}
                    margin="normal"
                  >
                    {localization.contact_us.contact_usBtnsAndlinks.submit}
                  </Button>
                </p>
              </ValidatorForm>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default withStyles(styles)(BoxCenter);
