import React, {useEffect,useCallback,  useState} from "react";
import { Provider } from "react-redux";
import store from './configure-store';
import { Router } from "react-router-dom";
import AppRouter from "./router";
import { makeStyles } from '@material-ui/core/styles';
import * as actions from "./redux/actions/authActions/auth.actions";
import history from "./services/history";

import { useDispatch, useSelector } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
// import localization from "./json/lang/conf.json";
// import conf from "./json/conf.json";
// import conf from '';
import Authorizated from './components/authorizated/authorizated';
import Tooltip from '@material-ui/core/Tooltip';
import appInfo from '../package.json';
import NoNoNo from './components/toast';
import OfflinePage from './components/home';
import AuthService from "./services/auth.service";
import { Typography } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import OfflineDialog from './components/offlineModal';
import Cookies from 'js-cookie';
import moment from "moment";
import sha1 from 'sha1';
import {Redirect} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
const mainStore = store;


const ToPageNotFound = () => {
  useEffect(()=> {

    window.location.href = '/'
  }, [])
  return <div></div>
}



const App = (props) => {
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const [internetConnDi, setInternetConnDi] = useState(false);
  const setIsConfirm = useCallback(
    (status) => dispatch(actions.setIsConfirm(status)),
    [dispatch]
  );
  const setLangValues = useCallback(
    (langValues) => dispatch(actions.setLangValues(langValues)),
    [dispatch]
  );

  const setLangConfig = useCallback(
    (conf) => dispatch(actions.setLangConf(conf)),
    [dispatch]
  );
  const [isOnl, setIsOnl] =  useState(navigator.onLine);
  const [isLog, setIsLog] =  useState(false);
  const [achtung, setAchtung] =  useState(false);
  const [langConf, setLangConf] =  useState('');
  const [servInfo, setServInfo] =  useState({});

  const localization = useSelector((state) => state.authReducer.lang_values);
  const language = useSelector((state) => state.authReducer.language);
  const languageVal = useSelector((state) => state.authReducer.lang_values);
  const getInf = () => {
    window.location.href = 'https://info.bregus.embedded.biz.ua';
  }

  useEffect(()=>{
    
  }, [])
  const changeLanguage = useCallback(
    (lang) => dispatch(actions.setLanguage(lang)),
    [dispatch]
  );

  useEffect(() => {
    // devicesList();

    setTimeout(function run() {
      debugger
      let logos = JSON.parse(localStorage.getItem('logos'));
      if(!logos){
        localStorage.setItem('logos', JSON.stringify({key: '', arr: []}));
        return;
      }
      
      if(navigator.onLine&&logos.key) {
        AuthService.logosErr(logos);
        localStorage.setItem('logos', JSON.stringify({key: '', arr: []}));
      }
      
      // devicesList();
      setTimeout(run, 10000);
    }, 1);
  }, []);


  useEffect(()=> {
    let login =  Cookies.get('hash_key');
    debugger
    let langs = '';
    if(login&&window.location.pathname!=='/page_info'){
      setIsLog(true);
    }
    console.log(window.location.pathname)
    fetch(`${process.env.PUBLIC_URL}/json/conf.json`).then(data=>data.json()).then((res)=>{
      setAchtung(res.achtung);
    });
    fetch(`${process.env.PUBLIC_URL}/json/lang/conf.json`).then(data=>data.json()).then((res)=>{
      
      let lang =  Cookies.get('language');
      changeLanguage(lang? lang : res.main_language );
      setLangConfig(res);
      setLangConf(res);
debugger
      fetch(`${process.env.PUBLIC_URL}${res.language_arr[lang? lang : res.main_language].language_url}`).then(data=>data.json()).then((res)=>{
        
        let cookie_consent  =  Cookies.get('cookie_consent');
    
        if(cookie_consent){ 
          Cookies.set('cookie_consent', 'true', {expires: 90})
        }
    
        if(!cookie_consent){
          confirm({
            title: res.titleConf, description: res.cookiePolice, 
            dialogProps: {
              style: {
                textAlign: 'center' ,
                borderRadius: 24,
                paddingBottom: 15
              }
            },
            confirmationButtonProps: {style: {
            background: '#5E5AB9',
            boxShadow: '-9px -9px 16px rgba(255, 255, 255, 0.6), 20px 22px 40px rgba(63, 60, 255, 0.12)',
            borderRadius: 12,
            color: 'white', 
            fontFamily: 'gilroy',
            fontWeight: 600,
            width: '43%', 
            marginRight: 10,
            marginBottom: 15,

          }},
          cancellationButtonProps: {
            style: {
             
              marginBottom: 15,
              fontWeight: 600,
              width: '43%', 
              background: 'linear-gradient(0deg, #E3E9F7, #E3E9F7)',
              boxShadow: '-9px -9px 24px rgba(255, 255, 255, 0.570094), 20px 22px 40px rgba(0, 0, 0, 0.0826595)',
              borderRadius: 12,
              marginLeft: 10,
              color: '#2F2A61'
            }
          }
        })
          .then(() => { Cookies.set('cookie_consent', 'true', {expires: 90}); 
          debugger
          setIsConfirm(true);
        })
          .catch(() => { 
            debugger
            setIsConfirm(false);
          });
        }

      });



    });
    debugger
    AuthService.getInfo().then((res)=>{
      setServInfo(res.data.server_info);
    })

    window.addEventListener("offline", function(e) {setIsOnl(false);})

  window.addEventListener("online", function(e) {setIsOnl(true)})
    

    

  }, [])

  // const getLangPath = async ()=> {
  //   let path  = await  import( './json/lang/en.json');



  //   return OtherComponent
  // }

  useEffect(()=> {
    if(langConf&&language){
      debugger

      fetch(`${process.env.PUBLIC_URL}${langConf.language_arr[language].language_url}`).then(data=>data.json()).then((res)=>{
        setLangValues(res);
      });
    }

  }, [language, langConf])

  useEffect(()=> {
    console.log('dsfsdf', languageVal);

  }, [languageVal])


  return  language && localization  ? (
 
  
    <Router history={history}>

      {
        // !isOnl ? 
        // <OfflinePage/> 
        // :
        achtung ? 
        <NoNoNo/> 
        :
        isLog ? <Authorizated setLog={setIsLog}/> :
        <AppRouter  />

      }

{
        internetConnDi ? < OfflineDialog internetConnDi={internetConnDi}  setInternetConnDi={setInternetConnDi}/> : null
      }
      <div style={{position:'absolute', left: 10, bottom: 10, display: 'flex', alignItems: 'center'}}>
        <Typography style={{color: '#5e5ab9'}}>{servInfo?.ver}—{appInfo.version}</Typography>
        <div style={!isOnl ? { marginLeft: 10, width:10, height: 10, borderRadius: 50, backgroundColor: '#fc5449'} : {marginLeft: 10,width:10, height: 10, borderRadius: 50, backgroundColor: 'green'}}></div>
      </div>


{   language && localization?   <Tooltip title= {localization.tooltips.helpPage} placement="top" arrow>
      <HelpIcon onClick={()=> {

if(!navigator.onLine) {
  let login = Cookies.get("hash_key");
  let errforLog = {
    timestamp: moment().unix(),
    login_hash: login ? login : "",
    point_error: "redirect on help page",
    error_msg: "Internet connection error",
  };
  let logos = JSON.parse(localStorage.getItem("logos"));
  logos.arr.push(errforLog);
  localStorage.setItem(
    "logos",
    JSON.stringify({ key: sha1(logos.arr), arr: logos.arr })
  );
  setInternetConnDi(true);
  return;
}
        getInf()
        
        }}  style={{position:'absolute', right: 10, bottom: 10, cursor: 'pointer'}}/>
      </Tooltip> : null}

    </Router>

  ) : null;
};

export default App;
