import React, { useEffect, useCallback, useState } from "react";
import Button from "@material-ui/core/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useInput } from "../../hooks/useInput";
import Cookies from "js-cookie";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import { ToastProvider, useToasts } from "react-toast-notifications";
import {  useSelector } from "react-redux";
import { Typography } from '@material-ui/core';


const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#5e5ab9",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#5e5ab9",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#5e5ab9",
      },
      "&:hover fieldset": {
        borderColor: "#5e5ab9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#5e5ab9",
      },
    },
    // underline: {
    //   "&&&:before": {
    //     borderBottom: "none"
    //   },
    //   "&&:after": {
    //     borderBottom: "none"
    //   }
    // }
  },
})(TextValidator);

const SignInForm = ({ isFetching, handleSubmit, fP, shFP, errSho }) => {
  const [isShowPass, setIsShowPass] = useState(false);

  const language = useSelector((state) => state.authReducer.language);
  const localization = useSelector((state) => state.authReducer.lang_values);
  const {
    value: username,
    bind: bindUserName,
    reset: resetUserName,
    setValue,
  } = useInput("");
  const {
    value: password,
    bind: bindPassword,
    reset: resetPassword,
    setValue: sP,
  } = useInput("");
  const [hoverRem, setHoverRem] = useState(false);
  useEffect(() => {
    ValidatorForm.addValidationRule("moreThanThreeChar", (value) => {
      
      return value.length >= 4 && value.length <= 40;
    })
    ValidatorForm.addValidationRule("notAllowedSpecialSymbols", (value) => {
      return !/[^A-zА-яЁё0-9]/.test(value);
    });

    return () => {
      ValidatorForm.removeValidationRule("moreThanThreeChar");
      ValidatorForm.removeValidationRule("moreThanThreeChar");
    };
  }, [ValidatorForm]);
  const { addToast } = useToasts();
  useEffect(() => {
if(username || password){
  errSho('');
}
  }, [username, password]);
  const isConfirm = useSelector((state) => state.authReducer.isConfirm);

  const handleSubmitCallback = useCallback(
    (evt) => {
      evt.preventDefault();
      handleSubmit({ login: username, pass: password });
    },
    [username, password, handleSubmit]
  );

  return (
    <ValidatorForm onSubmit={handleSubmitCallback}>
      <CssTextField
        fullWidth
        disabled={!isConfirm ? true : false}
        // label={localization.labelLog}
        name="login"
        style={{
          height: 44,
          background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
          boxShadow:
            "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
          borderRadius: 12,
          marginTop: 20,
        }}
        variant="filled"
        autoComplete="off"
        placeholder={localization.jointFormData.placeHolders.log}
        InputProps={{
          style: {
            background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
            boxShadow:
              "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
            borderRadius: 12,
          },
          underline: {
            "&&&:before": {
              borderBottom: "none",
            },
            "&&:after": {
              borderBottom: "none",
            },
          },
        }}
        {...bindUserName}
        validators={["required", "notAllowedSpecialSymbols", 'moreThanThreeChar']}
        errorMessages={[
          localization.jointFormData.errors.reqValid, 
          localization.jointFormData.errors.specialSymbolsValid,
          localization.jointFormData.errors.lengthValid
        ]}
        margin="none"
      />
      <CssTextField
        placeholder={localization.jointFormData.placeHolders.pass}
        fullWidth
        disabled={!isConfirm ? true : false}
        style={{
          height: 44,
          background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
          boxShadow:
            "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
          borderRadius: 12,
          marginTop: 20,
        }}
        variant="filled"
        // label={localization.labelPass}
        type={isShowPass ? null : "password"}
        {...bindPassword}
        color="#5e5ab9"
        InputProps={{
          style: {
            background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
            boxShadow:
              "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
            borderRadius: 12,
          },
          underline: {
            "&&&:before": {
              borderBottom: "none",
            },
            "&&:after": {
              borderBottom: "none",
            },
          },
          endAdornment: (
            <InputAdornment position="end">
              {isShowPass ? (
                <VisibilityOffIcon
                  color="#2F2A61"
                  onClick={() => setIsShowPass(!isShowPass)}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <VisibilityIcon
                  color="#2F2A61"
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsShowPass(!isShowPass)}
                />
              )}
            </InputAdornment>
          ),
        }}
        name="password"
        validators={["required", 'moreThanThreeChar']}
        errorMessages={[          localization.jointFormData.errors.reqValid, 
          localization.jointFormData.errors.lengthValid]}

        autoComplete="off"
      />

      <div
        style={{
          // display: "flex",
          // justifyContent: "space-around",
          marginTop: 2,
        }}
      >
     { shFP ? <Typography
        onMouseLeave={() => setHoverRem(false)}
        onMouseEnter={() => setHoverRem(true)}
        style={
          !hoverRem
            ? {
                color: "#999bba" ,
                marginTop: 15,
                height: 50,
                cursor: "pointer",
                textAlign: "center",
                marginLeft: 13,
                fontSize: 13,
              }
            : {
                color: '#2F2A61',
                marginTop: 15,
                height: 50,
                cursor: "pointer",
                textAlign: "center",
                marginLeft: 13,
                fontSize: 13,
              }
        }
        variant="contained"
        onClick={() => {

        fP(true);

        }}
      >
        {localization.login.loginBtnsAndlinks.forgotPass}
      </Typography> : null
}

        <Button
          style={{
            backgroundColor: "#5e5ab9",
            color: "#fff",
            marginTop: 30,
            height: 40,
            borderRadius: 20,
            textTransform: "none",
            fontWeight: "bold",
            fontSize: 14,
            fontFamily: "gilroy",
            width: "100%",
          }}
          variant="contained"
          disabled={!isConfirm ? true : false}
          type="submit"
          margin="normal"
        >
          {localization.login.loginBtnsAndlinks.login}
        </Button>


      </div>
    </ValidatorForm>
  );
};

export default SignInForm;
