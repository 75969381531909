
import Cookies from 'js-cookie';
export const isOnlineGuard = (to, from, next) => {
    if (to.meta.isOnline) {
      let login =  Cookies.get('hash_key');
      if(!login){
        debugger
        next.redirect('/');
      }
      next();
    } else {
      next();
    }
};
