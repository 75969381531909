import * as actionTypes from '../../actions/authActions/actions.types'
import Cookies from 'js-cookie';




const dynamicalInitialState = () => {
  let lang =  Cookies.get('language');
  let cookie_consent  =  Cookies.get('cookie_consent');


  const initialState = {
    isLoggedIn: false,
    allUsers: [],
    isConfirm: cookie_consent ? true : false, 
    isConnected: false,
    isFetching: false,
    language:  null,
    lang_values: null,
    lang_conf: null
    
  };
  return initialState;
}



export default function authReducer(state = dynamicalInitialState(), action) {
  switch (action.type) {
    case actionTypes.IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
      };

      case actionTypes.LANG_CONF:
        return {
          ...state,
          lang_conf: action.lang_conf,
        };

      case actionTypes.IS_CONFIRM:
        return {
          ...state,
          isConfirm: action.isConfirm,
        };


        case actionTypes.LANG_VALUES:
          return {
            ...state,
            lang_values: action.lang_values,
          };
      case actionTypes.SET_LANGUAGE:
        return {
          ...state,
          language: action.language,
        };
    case actionTypes.IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}
