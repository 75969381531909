import React, { useEffect, useCallback } from "react";
import Avatar from "@material-ui/core/Avatar";
import LockIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";

import * as actions from "../../redux/actions/authActions/auth.actions";
import BoxCenter from "../authPage/UI/box/Center";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import { logo } from "./../../assets/images";
import { ToastProvider, useToasts } from "react-toast-notifications";
// import  localization  from "../../json/lang/conf.json";
import Cookies from "js-cookie";
import AuthService from "./../../services/auth.service";

const Authorizated = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const localization = useSelector((state) => state.authReducer.lang_values);
  const isFetching = useSelector((state) => state.authReducer.isFetching);
  const language = useSelector((state) => state.authReducer.language);
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);
  const isConfirm = useSelector((state) => state.authReducer.isConfirm);
  useEffect(() => {}, [isLoggedIn]);

  const logout = async () => {
    let hash = await Cookies.get("hash_key");
    await AuthService.logout(hash);
    Cookies.remove("hash_key");
    window.location.href = "/";
  };

  return (
    <BoxCenter logIntoReg={() => props.history.push("/")}>
      <Typography
        component="h1"
        variant="h5"
        style={{
          marginTop: 30,
          marginBottom: 20,
          textTransform: "none",
          fontWeight: "bold",
          fontSize: 20,
          color: "#2F2A61",
          fontFamily: "gilroy",
        }}
      >
        {localization.authorizated.authorizatedTexts.achtungTitle}
      </Typography>

      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Button
          fullWidth
          style={{
            backgroundColor: "#57559a",
            width: "60%",
            color: "#fff",
            marginTop: 15,
            height: 40,
            borderRadius: 20,
            textTransform: "none",
            fontWeight: "bold",
            fontSize: 14,
            fontFamily: "gilroy",
          }}
          variant="contained"
          onClick={() => logout()}
        >
          {localization.authorizated.authorizatedBtnsAndlinks.logaut}
        </Button>
      </div>
    </BoxCenter>
  );
};

export default Authorizated;
