import { URL_API } from "../environment";
import axios from "axios";
import sha1 from 'sha1';


const getKey = ()=> {
  return  fetch(`${process.env.PUBLIC_URL}/json/conf.json`).then(data=>data.json()).then((res)=>res.keys.apiKey)
}

const getUrl =()=> {
  return  fetch(`${process.env.PUBLIC_URL}/json/conf.json`).then(data=>data.json()).then((res)=>res.url.api)
}
export default class AuthService {

  static loginUser = async (user) => {
    let apiKey = await getKey();
    let apiUrl = await getUrl();
    return axios.get(`${apiUrl}/${apiKey}/user/login?login=${user.login}&pass=${sha1(user.login+user.pass)}`)
  };

  static logout = async (hash) => {
    let apiKey = await getKey();
    let apiUrl = await getUrl();
    debugger
    return axios.get(`${apiUrl}/${apiKey}/user/logaut?hash_key=${hash}`)
  };


  static registerUser = async (user) => {
    let apiKey = await getKey();
    let apiUrl = await getUrl();
    return axios.post(`${apiUrl}/${apiKey}/user/reg?`, user)
  };

  static activateUser = async (hash) => {
    let apiKey = await getKey();
    let apiUrl = await getUrl();
    //13b70f66528cfce57063327076ca6954a9a6d9ef
    
    return axios.get(`${apiUrl}/${apiKey}/user/activate?mail_key=${hash.slice(1, hash.length)}`)
  };

  static getInfo =  async () => {
    let apiKey = await getKey();
    let apiUrl = await getUrl();
    return axios.get(`${apiUrl}/${apiKey}/user/im_alive`)
  };

  static resetPass =async (hash) => {
    let apiKey = await getKey();
    let apiUrl = await getUrl();
    return axios.get(`${apiUrl}/${apiKey}/user/reset_pass?hash=${sha1(hash)}`)
  };

  
  static contactForm =async (form) => {
    let apiKey = await getKey();
    let apiUrl = await getUrl();
    return axios.post(`${apiUrl} /${apiKey}/set/contact_form`, form)
  };

  static logosErr = async(form) => {
    let apiKey = await getKey();
    let apiUrl = await getUrl();
    return axios.post(`${apiUrl} /${apiKey}/set/front_catch_log`, form)
  };

  
}
