import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './configure-store';
import * as serviceWorker from './serviceWorker';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { ConfirmProvider } from 'material-ui-confirm';
import { Provider } from "react-redux";
// import conf  from `${process.env.PUBLIC_URL}/json/lang/conf.json`;


ReactDOM.render(
  <Provider store={store}>
  <ConfirmProvider>
  <ToastProvider>
  <App />
</ToastProvider>
</ConfirmProvider>
</Provider>
    ,
  document.getElementById('root')
);

serviceWorker.unregister();
