import React, { useEffect, useCallback, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import LockIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import SignInForm from "./form/SignInForm";
import * as actions from "../../redux/actions/authActions/auth.actions";
import BoxCenter from "./UI/box/Center";
import { useDispatch, useSelector } from 'react-redux';
import Button from "@material-ui/core/Button";
import SignUpForm from './form/SignUpForm';
import { logo } from './../../assets/images';
import { ToastProvider, useToasts } from 'react-toast-notifications';

import Cookies from 'js-cookie';
import moment from "moment";
import sha1 from 'sha1';
// import  localization  from "../../json/lang/conf.json";
import OfflineDialog from './../offlineModal';

const SignUp = (props) => {
  
  const dispatch = useDispatch();
  const { addToast } = useToasts()
  const [errorShow, setErrorShow] = useState("");
  const [internetConnDi, setInternetConnDi] = useState(false);
  const signUp = useCallback(
    (user, hist, addToast, lang) => dispatch(actions.signUp(user, hist, addToast, lang)),
    [dispatch]
  );

  const changeLanguage = useCallback(
    (language) => dispatch(actions.setLanguage(language)),
    [dispatch]
  );

  const localization = useSelector((state) => state.authReducer.lang_values);
  const language = useSelector(state => state.authReducer.language);
  const isFetching = useSelector(state => state.authReducer.isFetching);
  const isConfirm = useSelector(state => state.authReducer.isConfirm);
  const isLoggedIn = useSelector(state => state.authReducer.isLoggedIn);

  useEffect(()=> {
   if(isLoggedIn)
   props.history.push("/chat");
  }, [isLoggedIn])

  const register = (user ) => {
    if(!navigator.onLine) {
      let login = Cookies.get("hash_key");
      let errforLog = {
        timestamp: moment().unix(),
        login_hash: login ? login : "",
        point_error: "try register",
        error_msg: "Internet connection error",
      };
      let logos = JSON.parse(localStorage.getItem("logos"));
      logos.arr.push(errforLog);
      localStorage.setItem(
        "logos",
        JSON.stringify({ key: sha1(logos.arr), arr: logos.arr })
      );
      setInternetConnDi(true);
      return;
    }
      signUp(user, props.history, addToast, localization)
      .catch((ex) => {
        alert(ex.message);
      });
  }

  return language && localization ?  (
    <BoxCenter logIntoReg={()=>props.history.push('/')}  setInternetConnDi={setInternetConnDi}>
              {  !isConfirm ?  <Typography component="h1" variant="h5" style={{marginTop: 30, marginBottom: 20, textAlign: 'center',  textTransform:'none', fontWeight: 'bold',color: 'red', fontFamily: 'gilroy'}}>
              {localization.confirm_cookieErrors.notConfirmed}
      </Typography> : null}

      {errorShow ? (
        <Typography
          component="h1"
          variant="h5"
          style={
           {
                  marginTop: 30,
                  marginBottom: 20,
                  textTransform: "none",
                  fontWeight: "normal",
                  fontSize: 20,
                  color: "#fc5449",
                  fontFamily: "gilroy",
                  textAlign: 'center'
                }
          }
        >
          {errorShow}
        </Typography>
      ) : null}


      {localization?.register.titles.registerTitle?.length ?

<Typography component="h1" variant="h5" style={{marginTop: 30, textAlign: 'center', marginBottom: 20, textTransform:'none', fontWeight: 'bold',color: '#2F2A61', fontFamily: 'gilroy'}}>
{localization.register.titles.registerTitle}
</Typography> : null
      }
      {
        internetConnDi ? < OfflineDialog internetConnDi={internetConnDi}  setInternetConnDi={setInternetConnDi}/> : null
      }

      <SignUpForm
        props={props}
        handleSubmit={(data) => register(data)}
        isFetching={isFetching}
        errSh={setErrorShow}
      />


    </BoxCenter>
   ) : null;


}

export default SignUp;
