import React, { useEffect, useCallback, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import LockIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import SignInForm from "./form/SignInForm";
import * as actions from "../../redux/actions/authActions/auth.actions";
import BoxCenter from "./UI/box/Center";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import { logo } from "./../../assets/images";
import { ToastProvider, useToasts } from "react-toast-notifications";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withStyles } from "@material-ui/core/styles";
import sha1 from 'sha1';
import AuthService from "./../../services/auth.service";
import { useInput } from "./../hooks/useInput";
import OfflineDialog from './../offlineModal';
import { Cookies } from 'js-cookie';
import moment  from 'moment';

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#5e5ab9",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#5e5ab9",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#5e5ab9",
      },
      "&:hover fieldset": {
        borderColor: "#5e5ab9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#5e5ab9",
      },
    },
  },
})(TextValidator);

const SignIn = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [hoverReg, setHoverReg] = useState(false);
  const [hoverRem, setHoverRem] = useState(false);
  const signIn = useCallback(
    (user, hist, addToast, lang) =>
      dispatch(actions.signIn(user, hist, addToast, lang)),
    [dispatch]
  );
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput("");
  const [showContactForm, setShowContactForm] = React.useState(false);
  const isFetching = useSelector((state) => state.authReducer.isFetching);
  const language = useSelector((state) => state.authReducer.language);
  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);
  const isConfirm = useSelector((state) => state.authReducer.isConfirm);
  useEffect(() => {
    if (isLoggedIn) props.history.push("/");
  }, [isLoggedIn]);

  const [errorShow, setErrorShow] = useState("");
  const [cased, setCased] = useState("");
  const [showFP, setShowFP] = useState(false);
  const [internetConnDi, setInternetConnDi] = useState(false);
  const localization = useSelector((state) => state.authReducer.lang_values);

  const title = (tit, cased) => {
    setErrorShow(tit);
    setCased(cased);
  };

  const login = (user) => {
    if(!navigator.onLine) {
      let login = Cookies.get("hash_key");
      let errforLog = {
        timestamp: moment().unix(),
        login_hash: login ? login : "",
        point_error: "try login",
        error_msg: "Internet connection error",
      };
      let logos = JSON.parse(localStorage.getItem("logos"));
      logos.arr.push(errforLog);
      localStorage.setItem(
        "logos",
        JSON.stringify({ key: sha1(logos.arr), arr: logos.arr })
      );
      setInternetConnDi(true);
      return;
    }
    signIn(user, localization, title, setShowFP).catch((ex) => {
      alert(ex.message);
    });
  };

  useEffect(() => {
    console.log(email);
  }, [email]);

  const handleSubmitCallback = useCallback(async () => {
    debugger;
    if(!navigator.onLine) {
              let login = Cookies.get("hash_key");
            let errforLog = {
              timestamp: moment().unix(),
              login_hash: login ? login : "",
              point_error: "try reset pass",
              error_msg: "Internet connection error",
            };
            let logos = JSON.parse(localStorage.getItem("logos"));
            logos.arr.push(errforLog);
            localStorage.setItem(
              "logos",
              JSON.stringify({ key: sha1(logos.arr), arr: logos.arr })
            );
      setInternetConnDi(true);
      return;
    }
    await AuthService.resetPass(email);
    setShowContactForm(!showContactForm);
    addToast(localization.login.forgPassModal.alertInfo, {
      appearance: "info",
      autoDismissTimeout: 2000,
      autoDismiss: true,
    });
  }, [email, showContactForm]);

  return language && localization  ? (
    <BoxCenter logIntoReg={() => props.history.push("/")} setInternetConnDi={setInternetConnDi}>
      {!isConfirm ? (
        <Typography
          component="h1"
          variant="h5"
          style={{
            marginTop: 30,
            marginBottom: 20,
            fontWeight: "normal",
            textTransform: "none",
            color: "#fc5449",
            textAlign: 'center',
            fontFamily: "gilroy",
          }}
        >
          {localization.confirm_cookie.confirm_cookieErrors.notConfirmed}
        </Typography>
      ) : null}

      {
        internetConnDi ? < OfflineDialog internetConnDi={internetConnDi}  setInternetConnDi={setInternetConnDi}/> : null
      }
      {errorShow ? (
        <Typography
          component="h1"
          variant="h5"
          style={
            cased === "success"
              ? {
                  marginTop: 30,
                  marginBottom: 20,
                  fontSize: 20,
                  color: "#90E35B",
                  fontFamily: "gilroy",
                }
              : {
                  marginTop: 30,
                  marginBottom: 20,
                  textTransform: "none",
                  fontWeight: "normal",
                  fontSize: 20,
                  color: "#fc5449",
                  fontFamily: "gilroy",
                }
          }
        >
          {errorShow}
        </Typography>
      ) : null}

      <SignInForm
        handleSubmit={(data) => login(data)}
        isFetching={isFetching}
        fP={setShowContactForm}
        shFP={showFP}
        errSho={setErrorShow}
      />

      {/* 
              <Typography
          onMouseLeave={() => setHoverRem(false)}
          onMouseEnter={() => setHoverRem(true)}
          style={
            !hoverRem
              ? {
                  color: "#5e5ab9",
                  marginTop: 15,
                  height: 50,
                  cursor: "pointer",
                  textAlign: "center",
                  marginLeft: 20,
                  fontSize: 15,
                }
              : {
                  color: "#fff",
                  marginTop: 15,
                  height: 50,
                  cursor: "pointer",
                  textAlign: "center",
                  marginLeft: 20,
                  fontSize: 15,
                }
          }
          variant="contained"
          onClick={() => {

            setShowContactForm(true);

          }}
        >
          {localization.forgotPass}
        </Typography> */}
      {/* <Typography variant="body2" style={{marginTop: 30}}>
        or
      </Typography>
      <Typography variant="body2">
        sign in with
      </Typography>
      <div style={{marginTop: 15}}>

        <Button onClick={()=>alert('Данный метод недоступен')} style={{width: 120, height: 40, textTransform: 'none', backgroundColor:"#4267B2", color: '#fff', marginRight: 5}} variant="contained">Facebook</Button>
        <Button onClick={()=>alert('Данный метод недоступен')} style={{width: 120, height: 40, textTransform: 'none', backgroundColor:"#fff", color: '#000', marginLeft: 5}} variant="contained">Google</Button>
      </div> */}

      <Typography
        onClick={() => props.history.push("/register")}
        onMouseLeave={() => setHoverReg(false)}
        onMouseEnter={() => setHoverReg(true)}
        style={
          !hoverReg
            ? {
                marginTop: 35,
                color: "#999bba",
                fontSize: 14,
                fontFamily: "gilroy",
                fontSize: 15,
              }
            : {
                marginTop: 35,
                fontSize: 14,
                fontFamily: "gilroy",
                color: "white",
                cursor: "pointer",
                fontSize: 15,
                color: "#5e5ab9",
              }
        }
      >
        {localization.login.loginBtnsAndlinks.dntHaveAcc}
      </Typography>

      <Dialog
        maxWidth={"xs"}
        open={showContactForm}
        onClose={() => setShowContactForm(!showContactForm)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: "#5e5ab9",
            textAlign: "center",
            backgroundColor: "#e3e9f7",
          }}
        >
          {localization.login.forgPassModal.modalTitle}
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#e3e9f7" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ValidatorForm onSubmit={handleSubmitCallback}>
              <CssTextField
                fullWidth
                style={{
                  height: 44,
                  background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
                  boxShadow:
                    "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
                  borderRadius: 12,
                  // marginTop: 40,
                }}
                InputProps={{
                  style: {
                    background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
                    boxShadow:
                      "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
                    borderRadius: 12,
                  },
                  underline: {
                    "&&&:before": {
                      borderBottom: "none",
                    },
                    "&&:after": {
                      borderBottom: "none",
                    },
                  },
                }}
                variant="filled"
                placeholder={localization.jointFormData.placeHolders.email}
                {...bindEmail}
                type="Email"
                name="bodyMsg"
                validators={["required", "isEmail"]}
                errorMessages={[localization.jointFormData.errors.reqValid, localization.jointFormData.errors.errorEmail]}
                margin="dense"
              />
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button
                  style={{
                    background: '#5E5AB9',
                    boxShadow: '-9px -9px 16px rgba(255, 255, 255, 0.6), 20px 22px 40px rgba(63, 60, 255, 0.12)',
                    borderRadius: 12,
                    color: 'white', 
                    fontFamily: 'gilroy',
                    fontWeight: 600,
                  
               
                  }}
                  variant="contained"
                  type="submit"
                  margin="normal"
                >
                  {localization.login.forgPassModal.submitBtn}
                </Button>
              </p>
            </ValidatorForm>
          </div>
        </DialogContent>
      </Dialog>
      {/* 
      <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
      <Button 
      fullWidth 
      style={{backgroundColor:"#57559a", width: '60%',  color: '#fff', marginTop:15, height:40, borderRadius: 20, textTransform: 'none', fontWeight:'bold', fontSize: 14, fontFamily: 'gilroy'}}
            variant="contained"
            onClick={()=>props.history.push('/register')}>{localization.toRegPage}  </Button>

      </div> */}
    </BoxCenter>
  ) : null;
};

export default SignIn;
