import React, { useEffect, useCallback, useState } from "react";
import Button from "@material-ui/core/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useInput } from "../../hooks/useInput";
import validator from "validator";
import { ToastProvider, useToasts } from "react-toast-notifications";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
// import  localization  from "../../../json/lang/conf.json";
import { useDispatch, useSelector } from "react-redux";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#5e5ab9",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#5e5ab9",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#5e5ab9",
      },
      "&:hover fieldset": {
        borderColor: "#5e5ab9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#5e5ab9",
      },
    },
  },
})(TextValidator);

const SignUpForm = ({ isFetching, handleSubmit, props, errSh }) => {
  const { addToast } = useToasts();
  const {
    value: confirmPassword,
    bind: bindConfirmPassword,
    reset: resetConfirmPassword,
  } = useInput("");
  const {
    value: password,
    bind: bindPassword,
    reset: resetPassword,
  } = useInput("");
  const { value: login, bind: bindLogin, reset: resetLogin } = useInput("");
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput("");
  const language = useSelector((state) => state.authReducer.language);
  const [isShowPass, setIsShowPass] = useState(false);
  const isConfirm = useSelector((state) => state.authReducer.isConfirm);
  const [isShowConfirmPass, setIsShowConfirmPass] = useState(false);



  const [isShowConfirmPassValue, setIsShowConfirmPassValue] = useState(false);
  const [hoverRem, setHoverRem] = useState(false);

  useEffect(() => {
    if(login || email ||password){
      errSh('');
    }
  }, [login, email, password]);

  const resetAll = useCallback(() => {
    resetConfirmPassword();
    resetPassword();

    resetLogin();
    resetEmail();
  }, []);

  useEffect(() => {
    debugger;
    ValidatorForm.addValidationRule("moreThanThreeChar", (value) => {
      return value.length >= 4 && value.length <= 40;
    });
    ValidatorForm.addValidationRule("notAllowedSpecialSymbols", (value) => {
      return !/[^A-zА-яЁё0-9]/.test(value);
    });
    ValidatorForm.addValidationRule("zipCodeValidation", (value) => {
      return validator.isNumeric(value);
    });
    ValidatorForm.addValidationRule("phoneValidation", (value) => {
      return validator.isMobilePhone(value);
    });

    return () => {
      // ValidatorForm.removeValidationRule("moreThanThreeChar");
      // ValidatorForm.removeValidationRule("moreThanThreeChar");
    };
  }, [ValidatorForm]);

  const localization = useSelector((state) => state.authReducer.lang_values);
  useEffect(() => {
    console.log(isConfirm);
  }, [isConfirm]);

  const handleSubmitCallback = useCallback(
    (evt) => {
      evt.preventDefault();
      debugger;
      if (!isShowConfirmPass) {
        setIsShowConfirmPass(true);
        return;
      }

      if (confirmPassword !== password) {
        debugger;
        addToast("Passwords mismatch", {
          appearance: "error",
          autoDismissTimeout: 2000,
          autoDismiss: true,
        });
        return;
      }
      setIsShowConfirmPass(false);
      handleSubmit({ login: login, pass: password, mail: email });
    },
    [confirmPassword, email, password, login, handleSubmit, isShowConfirmPass]
  );

  return (
    <ValidatorForm onSubmit={handleSubmitCallback}>
      <CssTextField
        disabled={!isConfirm ? true : false}
        fullWidth
        style={{
          height: 44,
          background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
          boxShadow:
            "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
          borderRadius: 12,
          marginTop: 20,
          color: "#2F2A61",
        }}
        InputProps={{
          style: {
            background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
            boxShadow:
              "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
            borderRadius: 12,
          },
          underline: {
            "&&&:before": {
              borderBottom: "none",
            },
            "&&:after": {
              borderBottom: "none",
            },
          },
        }}
        variant="filled"
        placeholder={localization.jointFormData.placeHolders.log}
        {...bindLogin}
        name="login"
        validators={[
          "required",

          "notAllowedSpecialSymbols",
          "moreThanThreeChar",
        ]}
        errorMessages={[
          localization.jointFormData.errors.reqValid, 
          localization.jointFormData.errors.specialSymbolsValid,
          localization.jointFormData.errors.lengthValid
        ]}
        margin="none"
      />
      <CssTextField
        disabled={!isConfirm ? true : false}
        fullWidth
        style={{
          height: 44,
          background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
          boxShadow:
            "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
          borderRadius: 12,
          marginTop: 20,
          color: "#2F2A61",
        }}
        InputProps={{
          style: {
            background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
            boxShadow:
              "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
            borderRadius: 12,
          },
          underline: {
            "&&&:before": {
              borderBottom: "none",
            },
            "&&:after": {
              borderBottom: "none",
            },
          },
        }}
        variant="filled"
        placeholder={localization.jointFormData.placeHolders.email}
        type="email"
        {...bindEmail}
        name="email"
        validators={["required", "isEmail"]}
        errorMessages={[localization.jointFormData.errors.reqValid, localization.jointFormData.errors.emailValid,]}
        margin="none"
      />
      <CssTextField
        disabled={!isConfirm ? true : false}
        fullWidth
        style={{
          height: 44,
          background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
          boxShadow:
            "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
          borderRadius: 12,
          marginTop: 20,
          color: "#2F2A61",
        }}
        variant="filled"
        // label={localization.labelPass}
        placeholder={localization.jointFormData.placeHolders.pass}
        type={isShowPass ? null : "password"}
        {...bindPassword}
        InputProps={{
          style: {
            background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
            boxShadow:
              "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
            borderRadius: 12,
          },
          endAdornment: (
            <InputAdornment position="end">
              {isShowPass ? (
                <VisibilityOffIcon
                  color="#2F2A61"
                  onClick={() => setIsShowPass(!isShowPass)}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <VisibilityIcon
                  color="#2F2A61"
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsShowPass(!isShowPass)}
                />
              )}
            </InputAdornment>
          ),
        }}
        name="password"
        validators={["required", "moreThanThreeChar"]}
        errorMessages={[          localization.jointFormData.errors.reqValid, 
          localization.jointFormData.errors.lengthValid]}
        margin="dense"
      />

      {isShowConfirmPass ? (
        <CssTextField
          fullWidth
          style={{
            height: 44,
            background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
            boxShadow:
              "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
            borderRadius: 12,
            marginTop: 20,
            color: "#2F2A61",
          }}
          variant="filled"
          InputProps={{
            style: {
              background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
              boxShadow:
                "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
              borderRadius: 12,
            },
            endAdornment: (
              <InputAdornment position="end">
                {isShowConfirmPassValue ? (
                  <VisibilityOffIcon
                    color="#2F2A61"
                    onClick={() =>
                      setIsShowConfirmPassValue(!isShowConfirmPassValue)
                    }
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <VisibilityIcon
                    color="#2F2A61"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setIsShowConfirmPassValue(!isShowConfirmPassValue)
                    }
                  />
                )}
              </InputAdornment>
            ),
          }}
          // label="Confirm password"
          type={isShowConfirmPassValue ? null : "password"}
          {...bindConfirmPassword}
          name="confirmPassword"
          validators={["required", "moreThanThreeChar"]}
          errorMessages={[          localization.jointFormData.errors.reqValid, 
            localization.jointFormData.errors.lengthValid]}
          margin="none"
        />
      ) : null}

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "baseline",
          marginTop: 20,
        }}
      >
        <Button
          disabled={!isConfirm ? true : false}
          style={{
            backgroundColor: "#5e5ab9",
            paddingLeft: 35,
            minWidth: 150,
            paddingRight: 35,
            color: "#fff",
            marginTop: 15,
            height: 40,
            borderRadius: 20,
            textTransform: "none",
            fontWeight: "bold",
            fontSize: 14,
            fontFamily: "gilroy",
          }}
          variant="contained"
          type="submit"
          margin="normal"
        >
          {localization.register.registerBtnsAndlinks.register}
        </Button>
        <Typography
          onMouseLeave={() => setHoverRem(false)}
          onMouseEnter={() => setHoverRem(true)}
          style={
            !hoverRem
              ? {
                  color: "#999bba",
                  marginTop: 15,
                  height: 50,
                  cursor: "pointer",
                  textAlign: "center",
                }
              : {
                  color: "#5e5ab9",
                  marginTop: 15,
                  height: 50,
                  cursor: "pointer",
                  textAlign: "center",

                  //  fontSize: 15,
                }
          }
          variant="contained"
          onClick={() => props.history.push("/")}
        >
          {localization.register.registerBtnsAndlinks.backToLogin}
        </Typography>
      </div>
    </ValidatorForm>
  );
};

export default SignUpForm;
