
import  conf  from "../json/conf.json";

export const isAchtungGuard = (to, from, next) => {
    if (to.meta.isAchtung) {
      debugger
      if (!conf.achtung.status) {
        next.redirect('/');
      }
      next();
    } else {
      next();
    }
};