import React from 'react';
import BoxCenter from "./authPage/UI/box/Center";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";

import {warn} from '../assets/images'
import Typography from '@material-ui/core/Typography';

const OfflineDialog = ({internetConnDi, setInternetConnDi}) => {
    // const [internetConnDi, setInternetConnDi] = React.useState(false);
  const localization = useSelector((state) => state.authReducer.lang_values);
    return (
        <Dialog
        maxWidth={"xs"}
        open={ internetConnDi}
        onClose={ () => setInternetConnDi(!internetConnDi)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: "#2F2A61",
            textAlign: "center",
            backgroundColor: "#e3e9f7",
          }}
        >
            <img src={warn} alt="" width="100px"/>

            <Typography variant='h5'> { localization.offline.text}</Typography>
          
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#e3e9f7" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >

      
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                  
                <Button
                  style={{
                    background: '#5E5AB9',
                    boxShadow: '-9px -9px 16px rgba(255, 255, 255, 0.6), 20px 22px 40px rgba(63, 60, 255, 0.12)',
                    borderRadius: 12,
                    color: 'white', 
                    fontFamily: 'gilroy',
                    fontWeight: 600,
                    width: '100%'
               
                  }}
                  onClick={internetConnDi ? ()=>setInternetConnDi(false) : ()=>{}}
                  variant="contained"
                  margin="normal"
                >
                  { 'Ok'}
                </Button>
              </p>
          </div>
        </DialogContent>
      </Dialog>
  )};
export default OfflineDialog;