import React from "react";
import {
  Switch,
  Route,
} from "react-router-dom";
import SignIn from './components/authPage/SignIn';
import OfflinePage from './components/home';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import {isAuthGuard} from './guards/isAuthGuard';

import Loading from './components/loading';
import NotFound from './components/error';
import SignUp from './components/authPage/SignUp';
import { isOnlineGuard } from './guards/isOnline';
import PageInfo from './components/page_info';
import { isAchtungGuard } from './guards/isAchtungGuard';
import Authorizated from './components/authorizated/authorizated';





const AppRouter = (props) => {

return (
  <GuardProvider guards={[isAuthGuard, isOnlineGuard, isAchtungGuard]} loading={Loading} error={NotFound}>
        <Switch>
        
          <GuardedRoute path="/register" exact component={SignUp} meta={{  auth: true,}}/>
          <GuardedRoute path="/" exact component={SignIn} meta={{auth: true,}} />
          {/* <GuardedRoute path="/chat" exact component={ChatPage}  /> */}
          <GuardedRoute path="/page_info" exact component={PageInfo}  />
          {/* <GuardedRoute path="/authorizated" exact component={Authorizated}   meta={{isOnline: true, }} /> */}

        </Switch>
  </GuardProvider>
);}

export default AppRouter;



