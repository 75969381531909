import AuthService from "../../../services/auth.service";
import * as actionTypes from './actions.types';
import store from 'store';
import Toast from './../../../components/toast';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import moment from "moment";
import sha1 from 'sha1';


// import localization from '../../../json/lang/conf.json';
// import conf from '../../../json/conf.json';

export const setIsFetching = (isFetching) => {
  return {
    type: actionTypes.IS_FETCHING,
    isFetching,
  };
};

export const isSignedInUser = (isLoggedIn) => {
  return {
    type: actionTypes.IS_LOGGED_IN,
    isLoggedIn,
  };
};

export const setLangValues = (lang_values) => {
  return {
    type: actionTypes.LANG_VALUES,
    lang_values,
  };
};

export const setLangConf = (lang_conf) => {
  return {
    type: actionTypes.LANG_CONF,
    lang_conf,
  };
};

export const setIsConfirm = (isConfirm) => {
  return {
    type: actionTypes.IS_CONFIRM,
    isConfirm,
  };
};



export const setLanguage = (language) => {

  Cookies.set('language', language);
  return {
    type: actionTypes.SET_LANGUAGE,
    language,
  };
};

export function signIn(user, localization, errSh, shFP) {
  // if(!navigator.onLine){
  //   errSh(localization.offline);
  //   return;
  // }
  debugger
  return async (dispatch) => {
    await dispatch(setIsFetching(true));
    await AuthService.loginUser(user)
      .then( (response) => {
        debugger
        if(response.data.code ===200 || response.data.code ===201){
          // sessionStorage.setItem('user_key', response.data.info[2].user_key);
          // sessionStorage.setItem('asd', 'asdadsadasd');
          Cookies.set('hash_key', response.data.info.hash_key, {expires: 4})
          // addToast('Success',  { appearance: 'success', autoDismissTimeout: 2000, autoDismiss : true })
          errSh(localization.jointSuccesses.success, 'success');
          fetch(`${process.env.PUBLIC_URL}/json/conf.json`).then(data=>data.json()).then((res)=>{
            window.location.href = res.url.home;
          });
          
        } 
        if(response.data.code ===404){
          setlogos(localization.login.loginErrors.err404, "login form");
          errSh(localization.login.loginErrors.err404, 'error');
          return;
          // addToast(localization[lang].err404,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===401){
          setlogos(localization.login.loginErrors.err401, "login form");
          errSh(localization.login.loginErrors.err401, 'error');
           shFP(true);
           return;
          // addToast(localization[lang].err401,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
      })
      .catch((error) => {
        setlogos(JSON.stringify(error), "Login form");
        dispatch(setIsFetching(false));
      });
  };
}

export function activateUser( hist, hash) {


  // if(!hash) {
  //   hist.push('/');
  //   return;
  // }
  return async (dispatch) => await AuthService.activateUser(hash)
      // .then( (response) => {
      //   debugger
      //   if(response.data.code ===200 || response.data.code ===201){
      //     // sessionStorage.setItem('user_key', response.data.info[2].user_key);
      //     // sessionStorage.setItem('asd', 'asdadsadasd');
         
      //     addToast('Success',  { appearance: 'success', autoDismissTimeout: 2000, autoDismiss : true })

      //     window.location.href = conf.url.home;
      //   } 
      //   if(response.data.code ===404){
      //     addToast(localization[lang].err404,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
      //   }
      //   if(response.data.code ===401){
      //     addToast(localization[lang].err401,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
      //   }
      // })
      // .catch((error) => {
      //   console.log('ERROR', error);
      //   addToast('error',  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })

      // });
  
}


export const setlogos = (errText,point)=> {
  let login = Cookies.get("hash_key");
  let errforLog = {
    timestamp: moment().unix(),
    login_hash: login ? login : "",
    point_error: point,
    error_msg: errText,
  };
  let logos = JSON.parse(localStorage.getItem("logos"));
  logos.arr.push(errforLog);
  localStorage.setItem(
    "logos",
    JSON.stringify({ key: sha1(logos.arr), arr: logos.arr })
  );
}


export function signUp(user, hist, addToast, localization) {


  return async (dispatch) => {
    await dispatch(setIsFetching(true));
    await AuthService.registerUser(user)
      .then( (response) => {
        
        debugger

        if(response.data.code === 200 || response.data.code ===201){
          addToast(localization.jointSuccesses.success,  { appearance: 'success', autoDismissTimeout: 2000, autoDismiss : true })
          hist.push('/');
        } 
        if(response.data.code ===400){
          setlogos(localization.register.registerErrors.err400, "Register form");

          
          addToast(localization.register.registerErrors.err400,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }

        if(response.data.code ===406){
          setlogos(localization.register.registerErrors.err406, "Register form");
          addToast(localization.register.registerErrors.err406,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }

        if(response.data.code ===411){
          setlogos(localization.register.registerErrors.err411, "Register form");
          addToast(localization.register.registerErrors.err411,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===403){
          setlogos(localization.register.registerErrors.err403, "Register form");
          addToast(localization.register.registerErrors.err403,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
        if(response.data.code ===409){
          setlogos(localization.register.registerErrors.err409, "Register form");
          addToast(localization.register.registerErrors.err409,  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        }
    
        // const {token, isAdmin, nickNameColor, nickName} = response.data;
        // store.set("token", token);
        // store.set("userData", {isAdmin, nickName, nickNameColor});

        // dispatch(setIsFetching(false));
        // dispatch(isSignedInUser(true));
      })
      .catch((error) => {
        setlogos(JSON.stringify(error), "Register form");
        addToast('error',  { appearance: 'error', autoDismissTimeout: 2000, autoDismiss : true })
        dispatch(setIsFetching(false));
      });
  };
}
